<template>
	<div class="main">
		<div class="title" v-if="show" style="position: relative">
			<div class="left">
				<div class="left_box_one">
					<div class="left_box_one_str_left">{{mbti.result}}</div>
					<div class="left_box_one_str_rigth">
						<div style="width: 100%;height: 15rem"></div>
						{{ mbti.evaluate  }}
					</div>
				</div>
				<div class="left_box_tow" v-if="mbti.personality">
					<div class="left_box_tow_left" v-if="mbti.personality[0]">{{ mbti.personality[0] }}</div>
					<div class="left_box_tow_rigth" v-if="mbti.personality[1]">{{ mbti.personality[1] }}</div>
				</div>
				<div class="left_box_public">测试人员：{{mbti.student}}</div>
				<div class="left_box_public" v-if="mbti.personality">完成时间：{{mbti.completion_at}}</div>
			</div>
			<div class="right">
				<div id="main" style="width: 90%"></div>
			</div>
			<img :src="this.$OssAddress + 'left.png'" style="position: absolute;left: 35%"/>
			<img :src="this.$OssAddress + 'right.png'" style="position: absolute;right: 0;width: 120rem;top: 0"/>
		</div>

		<div class="body" v-if="show">
			<div class="body_title">
				<div style="width: 4rem;height: 27rem;background: #5970FF;border-radius: 13rem;margin-right: 19rem;line-height: 48rem"></div>
				<div style="font-size: 21rem;font-weight: normal;color: #333333;">测试结果分析</div>
			</div>

			<div class="body_progress" v-for="item in analysis">
				<div
					style="width: 32rem;height: 32rem;background: #739AFF;border-radius: 50%;font-size: 19rem;font-weight: normal;color: #FFFFFF;line-height: 32rem;text-align: center">
					{{ item[0].letter }}
				</div>
				<div style="width: 80%;height: 32rem;background: #EEEEEE;border-radius: 27rem;display: flex">
					<div class="left">
						<div class="left_bg" :style="{width:item[0].percentage}">{{item[0].percentage}}</div>
					</div>
					<div class="right">
						<div class="right_bg" :style="{width:item[1].percentage}">{{ item[1].percentage }}</div>
					</div>
				</div>
				<div
					style="width: 32rem;height: 32rem;background: #695EE1;border-radius: 50%;font-size: 19rem;font-weight: normal;color: #FFFFFF;line-height: 32rem;text-align: center">
					{{ item[1].letter }}
				</div>
			</div>
		</div>

		<div class="body" v-if="show">
			<div class="body_title">
				<div style="width: 4rem;height: 27rem;background: #5970FF;border-radius: 13rem;margin-right: 19rem"></div>
				<div style="font-size: 21rem;font-weight: normal;color: #333333;">性格特征</div>
			</div>

			<div class="characteristic" v-for="item in character">
				<div v-for="item_ in item" style="letter-spacing: 1.5rem">{{item_}}</div>
			</div>
		</div>

		<div class="body" v-for="item in copywriting" v-if="show">
			<div style="display: flex" class="body_title">
				<div style="width: 4rem;height: 27rem;background: #5970FF;border-radius: 13rem;margin-right: 19rem"></div>
				<div style="font-size: 21rem;font-weight: normal;color: #333333;">{{ item.name }}</div>
			</div>
			<div style="width: 100%;height: 4rem"></div>
			<div style="display: flex;padding: 8rem 50rem 0;" v-for="item in item.data">
				<div style="width: 7rem;height: 7rem;background: #666666;margin-right: 19rem;border-radius: 50%;margin-top: 6rem"></div>
				<div style="font-size: 16rem;font-weight: normal;color: #666666;width: 90%;letter-spacing: 1.5rem">{{ item }}</div>
			</div>
			<div style="width: 100%;height: 12rem"></div>
		</div>

		<div style="background: #fff">
			<t-result type='empty' v-if="!show" text="此学生还没做测试，暂无数据"></t-result>
		</div>

		<el-button style="margin-top: 12rem;margin-left: 12rem" @click="()=>{this.$router.back()}">返回</el-button>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	_config:{"route":{"path":"examine","meta":{"title":"查看","style":1}}},
  data () {
		return {
			mbti:{},
			analysis: [],
			pie: [],
			copywriting: [],
			character: [],
			show: false
		}
	},
	mounted() {
	},
	methods:{
		data(){
			setTimeout(()=>{
				if (this.show) {
					let chartDom = document.getElementById('main');
					let myChart = echarts.init(chartDom);
					let option = {
						radar: {
							areaStyle: {
								color: ["#2a4a93"] //图表背景网格的颜色
							},
							indicator: [
								{name: '外向（E)', max: 27},
								{name: '感知（P）', max: 27},
								{name: '思考（T）', max: 27},
								{name: '直觉（N）', max: 27},
								{name: '内向（I）', max: 27},
								{name: '判断（J）', max: 27},
								{name: '情感（F）', max: 27},
								{name: '感觉（S）', max: 27},
							],
							axisName: {
								color: '#666666'
							},
							splitArea: {
								show: false,
							},
							axisLine: {
								lineStyle: {
									color: '#fff'
								}
							},
						},
						series: [
							{
								name: 'Budget vs spending',
								type: 'radar',
								symbol: 'none',
								data: [
									{
										value: this.pie,
										name: 'Allocated Budget',
										areaStyle: {
											color: '#dcdafa'
										}
									},
								],
								lineStyle: {
									normal: {
										type: 'sold',
									}
								},

							}
						]
					};
					option && myChart.setOption(option);
				}
			},100)
		}
	},
	created() {
		this.$_axios2.get('api/evaluation/result/details/?id='+this.$route.query.id).then(res=>{
			if (res.data.data.state === 0){
				this.mbti = res.data.data
			}else{
				this.show = true
				this.mbti = res.data.data.mbti
				this.analysis = res.data.data.analysis
				this.copywriting = res.data.data.copywriting
				this.character = res.data.data.character
				const arr = ['E','P','T','N','I','J','F','S']
				arr.forEach((item,index)=>{
					this.pie[index] = res.data.data.pie.find(item_=>item_.letter === item).score
				})
				setTimeout(()=>{
					this.data()
				},10)
			}
		})
	}
}
</script>

<style scoped lang="scss">
.main {
	width: 100%;
	min-height: 100%;
	background-size: 100%;
	background: #f7f7f7 !important;
}

.title {
	width: 100%;
	height: 280rem;
	background: #FFFFFF;
	margin: auto;
	display: flex;
	padding: 24rem 120rem;

	div {
		width: 50%;
		height: 231rem;
	}

	.left {
		padding-top: 38rem;

		.left_box {
			display: flex;
			width: 100%;
			margin-left: 46rem;
		}

		.left_box_one {
			height: 45rem;
			margin-left: 46rem;
			display: flex;

			.left_box_one_str_left {
				font-size: 45rem;
				font-weight: normal;
				color: #333333;
				min-width: 137rem;
			}

			.left_box_one_str_rigth {
				font-size: 21rem;
				font-weight: normal;
				color: #333333;
				min-width: 128rem;
				margin-left: 12rem;
			}
		}

		.left_box_tow {
			width: 100%;
			height: 27rem;
			margin-top: 10rem;
			margin-left: 46rem;
			display: flex;
			text-align: center;
			line-height: 27rem;
			font-size: 19rem;
			font-weight: normal;
			color: #FFFFFF;

			.left_box_tow_left {
				width: 137rem;
				height: 100%;
				background: #ffc75a;
				border-radius: 7rem 7rem 7rem 7rem;
			}

			.left_box_tow_rigth {
				width: 123rem;
				height: 100%;
				background: #8282ff;
				margin-left: 10rem;
				border-radius: 7rem 7rem 7rem 7rem;
			}
		}

		.left_box_public {
			margin-top: 13rem;
			margin-left: 53rem;
			height: 20rem;
			width: 100%;
			font-size: 16rem;
			font-weight: normal;
			color: #666666;
		}
	}

	.right {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	}
}

.body {
	width: 99%;
	min-height: 80rem;
	background: #FFFFFF;
	border-radius: 13rem 13rem 13rem 13rem;
	opacity: 1;
	margin: 13rem;
	padding-bottom: 12rem;

	.body_title {
		display: flex;
		width: 1178rem;
		height: 48rem;
		background: linear-gradient(270deg, rgba(233, 238, 255, 0) 10%, #E9EEFF 100%);
		border-radius: 13rem 13rem 0 0;
		align-items: center;
		padding-left: 24rem;
	}

	.body_progress {
		margin: 24rem 63rem;
		display: flex;
		justify-content: space-between;
		padding: 0 120rem;

		.left {
			width: 50%;
			height: 100%;
			display: flex;
			justify-content: flex-end;

			.left_bg {
				//width: 50%;
				height: 100%;
				background: #739aff;
				border-radius: 25rem 0 0 25rem;
				font-size: 19rem;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 32rem;
				text-align: center;
			}
		}

		.right {
			width: 50%;
			height: 100%;

			.right_bg {
				//width: 50%;
				height: 100%;
				background: #695EE1;
				border-radius: 0 25rem 25rem 0;
				font-size: 19rem;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 32rem;
				text-align: center;
			}
		}
	}

	.characteristic {
		margin: 0 50rem;
		min-height: 67rem;
		font-size: 16rem;
		font-weight: normal;
		color: #666666;
		border-bottom: 1rem solid #EEEEEE;
		//display: flex;
		//align-items: center;
		line-height: 24rem;
		padding: 24rem 0;
	}
}
</style>
